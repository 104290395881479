.wrapper {
  margin: auto;
  width: 1200px;
  // display: flex;
  // height: 100%;
  overflow: hidden;
  // align-items: center;
  // justify-content: space-between;
  // display: table;
  .logo {
    // display: table-cell;
    // vertical-align: middle;
    float: left;
  }
  .info {
    height: 100%;
    float: right;
  }
}

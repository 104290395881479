.header {
  width: 100%;
  // height: 70px;
  padding: 10px 0;
  background: #fff;
}
.main {
  flex: 1;
  margin-top: 25px;
  // overflow: auto;
}
.content {
  box-sizing: border-box;
  margin: auto;
  position: relative;
  width: 1200px;
  height: 100%;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    background: #fff;
    height: 100%;
    width: 200px;
  }
}

.side {
  position: relative;
  z-index: 2;
  // float: left;
  vertical-align: top;
  display: inline-block;
  width: 200px;
  background: #fff;
}
.inner {
  // vertical-align: top;
  width: 980px;
  // float: right;
  display: inline-block;
  margin-left: 20px;
  background: #fff;
  // flex: 1;
}
:global {
  .page-inner {
    padding: 0 20px;
  }
}

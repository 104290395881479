@mixin page ($page) {
  .#{$page} {
    @content;
  }
}
@mixin content ($content) {
  @at-root {
    #{& + $content-separator + $content} {
      @content;
    }
  }
}

@mixin modify ($content) {
  @at-root {
    #{& + $modifier-separator + $content} {
      @content;
    }
  }
}

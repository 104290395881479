.wrapper {
  display: inline-block;
  font-size: 0;
}

.label, .inner {
  float: left;
}

.label {
  font-size: 14px;
  color: #555555;
  &.normal {
    text-align: right;
  }
}
.inner {
  vertical-align: baseline;
}
.group {
  overflow: hidden;
  padding: 13px 0;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
.label-size-normal {
  height: 30px;
  line-height: 30px;
}
.label-size-big {
  height: 36px;
  line-height: 36px;
}

.wrapper {
  text-align: center;
  height: 100%;
  width: 100%;
  background: #fff;
  &::before {
    content: "";
    left: 0;
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
  }
  .content {
    width: 600px;
    // height: 360px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
  .btn {
    margin: 30px 0;
  }
}

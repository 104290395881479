body {
  .ant-message {
    z-index: 1010000;
  }
}

.select2 {
  display: block;
}

.form-item-filter {
  margin-right: 44px;
}

.form-item-normal {
  margin-right: 14px;
}

.ant-input {
  color: inherit;
}

.ant-input,
.ant-btn,
.ant-select,
.ant-select-selection,
.range-date-picker .ant-calendar-picker-input {
  line-height: 28px;
}

.ant-calendar-range-picker-input {
  display: inline-block;
}

.range-date-picker .ant-calendar-picker-input {
  height: 32px;
  line-height: 32px;
}

.range-date-picker .ant-input {
  padding-top: 0;
  padding-bottom: 0;
}

.page-form {
  .ant-form-item-control {
    line-height: 34px;
  }
  .ant-form-item-label {
    line-height: 34px;
  }
  .ant-form-item-label label {
    color: #888;
  }
  .ant-btn {
    height: 36px;
    line-height: 34px;
  }
  .ant-input {
    height: 36px;
  }
  .ant-select-selection {
    line-height: 34px;
  }
  .ant-select,
  .ant-select-selection {
    min-height: 36px;
    height: auto;
  }
  .ant-select-selection__placeholder,
  .ant-select-selection__rendered {
    height: 34px;
  }
  .ant-select-selection-selected-value {
    line-height: 34px;
    height: 34px;
  }
  textarea.ant-input {
    height: auto;
  }
  .ant-select-selection__rendered {
    line-height: 30px;
  }
  .ant-input-number {
    height: 36px;
  }
  .ant-input-number-input {
    height: 34px;
  }
  .ant-select-selection--multiple>ul>li,
  .ant-select-selection--multiple .ant-select-selection__rendered>ul>li {
    height: 28px;
    line-height: 26px;
  }
  .ant-upload.ant-upload-select {
    vertical-align: top;
  }
  .ant-upload-list-text {
    .ant-upload-list-item {
      height: 34px;
      line-height: 34px;
      .anticon {
        top: 11px;
      }
    }
  }
}

.ant-upload-list-text {
  .ant-upload-list-item:first-child {
    margin-top: 0;
  }
}

.ant-select-selection__rendered {
  line-height: 30px;
}

.ant-input-lg {
  height: 36px;
  font-size: 14px;
}

.ant-select {
  width: 100%;
}

.ant-select-selection {
  line-height: 30px;
}

.ant-select-selection--single {
  height: 32px;
}

.ant-btn {
  vertical-align: top;
  a:hover {
    color: inherit;
  }
}
.avatar-uploader>.ant-upload {
  width: 88px;
  height: 88px;
}
.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: none;
}

// .ant-upload.ant-upload-select-picture-card {
//   background: #fff;
// }
// .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
//   background: inherit;
// }

// .ant-menu-inline {
//   .ant-menu-item {
//     // &.ant-menu-item-selected > a, &.ant-menu-item-selected > a:hover {
//     //   color: #fff;
//     // }
//   }
// }


.upload {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file {
  cursor: pointer;
  &:hover {
    color: #f0504a;
  }
  .close {
    margin-left: 10px;
  }
}

.dateBox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}
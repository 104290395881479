.filter {
  padding: 7px 0;
  .right {
    // padding-right: 14px;
    float: right;
    margin: 15px 0 0;
  }
  
}
.costFilter{
    display: flex;
    align-items: center;
    .costRgiht{
        float: right;
        margin-left: 15px;
      }
}
.header {
  overflow: hidden;
  height: 60px;
  padding: 0 24px;
  background: #FAFBFA;
  // border-bottom: 1px solid #ccc;
  .title {
    height: 100%;
    line-height: 60px;
    float: left;
    text-align: center;
    font-size: 18px;
    color: #555555;
    font-weight: bold;
  }
  .crm {
    float: right;
    color: #777;
    font-size: 12px;
  }
  .split {
    font-size: #777;
    padding: 0 3px;
  }
}

.form {
  width: 700px;
  padding-top: 30px;
  .ant-form-item-label {
    label {
      color: #888;
    }
  }
  .tip {
    font-size: 12px;
    color: #999;
  }
  .button, .button-back {
    margin-right: 20px;
    width: 100px;
    height: 36px;
    line-height: 36px;
  }

  .button-back {
    display: inline-block;
    text-align: center;
    border: 1px solid #DF383F;
    color: #DF383F;
  }
  // .button-back:active {
  //   color: #bd1319;
  //   border-color: #bd1319;
  // }
  // .button-back:hover {
  //   color: #f0504a;
  //   border-color: #f0504a;
  // }
}

.table-operations {
  border-top: 1px solid #efefef;
  padding: 15px 0;
  & > button {
    margin-right: 8px;
  }
}

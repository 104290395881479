.img {
  cursor: pointer;
  max-width: 60px;
  max-height: 40px;
}
// .wrapper {
//   // transform: translateY()
// }
:global {
  .img-viewer {
    .ant-modal-content {
      height: 100%;
    }
    .ant-modal-body {
      height: 100%;
      overflow: auto;
    }
  }
}

body,
html,
#root {
  height: 100%;
}

.page-btn {
  font-size: 14px;
  color: #2E79DF;
}

.link-handle {
  font-size: 12px;
  color: #2E79DF;
  margin: 0 7px;
  &:hover:not(.link-handle-disabled) {
    color: #f0504a;
  }
  &[disabled],
  &.link-handle-disabled {
    pointer-events: none;
    cursor: not-allowed;
    color: #999;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.w-e-toolbar {
  flex-wrap: wrap;
}

input[type="color"] {
  padding: 0 0 0 0px;
  border: 1px solid #d9d9d9;
  min-height: 34px; // height: ;
  vertical-align: middle;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
  }
  &::-moz-color-swatch {
    border: none;
  }
  &::-moz-color-swatch-wrapper {
    padding: 0;
  }
  &:focus {
    border-color: #f0504a;
    outline: 0;
    box-shadow: 0 0 0 2px rgba(227, 36, 35, 0.2);
    border-right-width: 1px !important;
  }
}

img {
  max-width: 100%;
}

.root {
  overflow: auto;
  background: #f5f5f5;
  padding-bottom: 33px;
  display: flex;
  flex-flow: column;
}

$flex: 'flex';

@include page($flex) {
  display: flex;
  @include modify(space-between) {
    justify-content: space-between;
  }
}

.bf-container.fullscreen {
  width: 100%!important;
}

video {
  max-width: 100%;
}

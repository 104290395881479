.login-info {
  font-size: 12px;
  color: #555;
  display: table;
}
.inner {
  height: 30px;
  line-height: 30px;
  display: table-cell;
  vertical-align: middle;
}

body {
  font-family: "Microsoft YaHei", "微软雅黑", "宋体", "simsun", "Arial", "Arial Narrow";
  line-height: 1;
  color: #333333;
}

div,
ul,
li,
p,
h1,
h2,
h3,
h4,
h5,
h6,
article,
header,
button,
input,
body,
html {
  margin: 0;
  padding: 0;
  margin-block-end: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

a:focus {
  text-decoration: none;
}

ul,
li {
  list-style: none;
}

button {
  background: none;
  cursor: pointer;
}

button,
input {
  outline: none;
  border: none;
}

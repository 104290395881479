.wrapper {
  position: relative;
  width: 195px;
  .picker {
    position: absolute;
    left: 0;
    bottom: 40px;
    z-index: 10;
  }
  .result {
    cursor: pointer;
    width: 100%;
  }
  .popover {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    left: 0;
    top: 0;
    background: transparent;
  }
}

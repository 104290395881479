.styleFormItem {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 10px 0;
}
.operateFormItem {
  text-align: right;
  padding-top: 14px;
}
.fixRangDateStyle {
  display: flex;
  align-items: center;
  :global(.ant-input) {
    line-height: 22px;
  }
}

.editor {
  border: 1px solid #d1d1d1;
  border-radius: 5px;
  width: 666px;
  position: relative;
  z-index: 10; // height: 300px;
  // min-height: 300px;
}

.upload {
  color: #666;
  padding: 0 15px;
  overflow: hidden;
  .item {
    position: relative;
    float: left;
    border: 1px dashed #d1d1d1;
    padding: 5px;
    box-sizing: border-box;
    background: #fff;
    height: 290px;
    width: 290px;
    .close {
      cursor: pointer;
      position: absolute;
      z-index: 2;
      right: 5px;
      top: 5px;
    }
  }
  .item:first-child {
    margin-right: 20px;
  }
  .title,
  .content {
    font-size: 14px;
  }
  .content {
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
  }
  .viewer {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      max-height: 100%;
    }
    &.viewer-image {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .fileError {
    color: #f00;
    font-size: 12px;
    padding: 0 15px;
    line-height: 1.5;
    text-align: center;
  }
}

.table2 {
  table {
    table-layout: fixed;
    border: 1px solid #ddd;
    border-bottom: none;
    th, td {
      font-size: 12px;
    }
    th {
      color: #555;
      font-weight: bold;
      height: 36px;
      padding: 0;
    }
    td {
      padding:0 6px;
      line-height: 16px;
      color: #555;
      border: 1px solid #ddd;
      height: 77px;
      word-break: break-all;
    }
  }
}

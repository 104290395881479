.wrapper {
  height: 54px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #ddd;
  position: relative;
}

.title {
  min-width: 100px;
  text-align: center;
  font-size: 18px;
  color: #555555;
  font-weight: bold;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -2px;
    width: 100px;
    height: 2px;
    background: #E6222A;
  }
}

.wrapper {
  height: 30px;
}
.header {
  padding-left: 10px;
  border: 1px solid #91BAF0;
  font-size: 14px;
  color: #555555;
  line-height: 28px;
  overflow: hidden;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}
.result {
  float: left;
}
.btn {
  float: right;
}

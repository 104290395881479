.wrapper {
  .item {
    position: relative;
    width: 96px;
    height: 96px;
    &:hover .mask {
      // background: rgba(0, 0, 0, 0.5);
      opacity: 1;
    }
  }
  .mask {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: all .3s;
  }
  .btns {
    height: 14px;
    position: absolute;
    width: 100%;
    left: 0;
    top: 50%;
    margin-top: -7px;
    text-align: center;
    line-height: 1;
  }
  .previewBtn, .deleteBtn {
    margin: 0 8px;
    color: #ddd;
  }
  .upload {
    display: block;
    overflow: hidden;
  }
}
.plus {
  font-size: 39px;
  color: #ddd;
}
.img {
  width: 100%;
  height: 100%;
}
.status {
  color: #999;
  text-align: center;
  font-size: 14px;
  line-height: 1.5;
}
